.game-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; /* Ensure the carousel takes the full width of the container */
  }
  
  .game-slides {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; /* Ensure the slides take the full width */
  }
  
  /* Common styles for all images */
  .game-image {
    width: 30%; /* Adjust the width to control size */
    flex-shrink: 0; /* Prevent resizing */
    transition: transform 0.5s ease;
    position: relative;
  }
  
  .main-image {
    z-index: 2; /* Make the main image appear above others */
  }
  
  .side-image {
    opacity: 0.6; /* Slightly faded to give depth */
    z-index: 1;
  }
  
  /* Position images for overlapping effect */
  .left2 {
    transform: translateX(-20%); /* Adjust for overlapping */
  }
  
  .right2 {
    transform: translateX(20%); /* Adjust for overlapping */
  }
  
  .game-info {
    text-align: center;
    position: absolute;
    width: 30%; /* Match image width for text alignment */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3; /* Text above images */
  }
  
  .button2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #D9D9D9;
    color: black;
    border: none;
    padding: 5px;
    cursor: pointer;
    z-index: 4; /* Buttons above everything */
    width: 50px; /* Fixed width for buttons */
    height: 50px;
    border-radius: 100%;
  }
  
  .button2:focus {
    outline: none;
  }

  