html {
  scroll-behavior: smooth;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid #1a1a1a;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
