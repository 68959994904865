@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

body {
  background-color: black;
  margin: 0;
  color: white;
  font-family: "Inter", sans-serif;
}

.App {
  background-color: black;
  min-height: 100vh;
}

.gradient-text {
  background: linear-gradient(45deg, #9968ff 0%, #ff6cb2 50%, #ffaf56 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-gradient-r-purple-pink-orange {
  position: relative;
}
.border-gradient-r-purple-pink-orange::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(to right, #9968ff, #ff6cb2, #ffaf56);
  padding: 2px;
}

.team-line::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; /* Adjust based on your layout */
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  transform: translateX(-50%);
}

.border-gradient {
  border-image: linear-gradient(to bottom, #9968ff, #ff6cb2, #ffaf56) 1;
}

/* .metric-card {
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #9968FF, #FF6CB2, #FFAF56) 1;
} */

.gradient-border {
  position: relative;
  overflow: hidden; /* Clip the pseudo-element to the border, important if the sizes are off */
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: -5px; /* Increasing border size to make it visible */
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1; /* Ensure this is below the content */
  background: linear-gradient(
    to right,
    crimson,
    blue
  ); /* Adjust gradient colors as needed */
  border-radius: 0.5rem; /* Match your border-radius if any */
}
